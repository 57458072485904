import './App.css';
import { useRive } from '@rive-app/react-canvas';
import riveFile from './logo.riv';
import welcomeRiveFile from './welcome.riv';
import appStoreButton from './app-store.svg';
import googlePlayButton from './google-play.png';
import monoRiveFile from './mono_button.riv';
import React, { useState } from 'react';
import '@fontsource/geologica';
import { Helmet } from 'react-helmet';

function App() {
  const { RiveComponent: ButtonRive } = useRive({
    src: riveFile,
    autoplay: true,
    stateMachines: ["State Machine 1"]
  });

  const { RiveComponent: MonoButtonRive } = useRive({
    src: monoRiveFile,
    autoplay: true,
    stateMachines: ["State Machine 1"]
  });

  const { RiveComponent: WelcomeRiveComponent } = useRive({
    src: welcomeRiveFile,
    autoplay: true,
    artboard: "logo"
  });

  const xHandle = {
    0: '@novikoff',
    1: '@a_sidenko',
    2: '@krickonaut',
    3: '@chunkodotuk',
    4: '@linuxLIN',
    5: '@krickonaut',
    6: '@narawastu',
    7: '@iryna_soliar',
    8: '@iryna_soliar',
    9: '@chunkodotuk'
  };

  const xMonoHandle = {
    0: '@novikoff',
    1: '@fritzklug',
    2: '@luminezcent',
    3: '@timsondrup',
    4: '@ramrodser',
    5: '@JoeMorrissey',
    6: '@campero_depollo',
    7: '@Jxvxt',
    8: '@khaledbouya',
    9: '@novikoff',
    10: '@madebyninefox',
    11: '@chunkodotuk',
    12: '@krickonaut',
    13: '@chunkodotuk'
  };

  const monoImages = Array.from({ length: 9 }, (_, i) => `/images/mono/${i + 1}.jpeg`);
  const images = Array.from({ length: 9 }, (_, i) => `/images/zero/${i + 1}.jpeg`);

  // Fisher-Yates (Knuth) shuffle algorithm for proper randomization
  const shuffle = (array) => {
    const result = [...array];
    for (let i = result.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [result[i], result[j]] = [result[j], result[i]]; // Swap elements
    }
    return result;
  };

  // Combine mono and zero images with their types
  const allImages = [
    ...monoImages.map((img, index) => ({ 
      src: img, 
      type: 'mono', 
      handle: xMonoHandle[index] 
    })), 
    ...images.map((img, index) => ({ 
      src: img, 
      type: 'Zerocam', 
      handle: xHandle[index] 
    }))
  ];
  
  // Apply proper shuffling
  const shuffledImages = shuffle(allImages);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentMonoImageIndex, setCurrentMonoImageIndex] = useState(0);
  const [activePage, setActivePage] = useState('original');
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <>
      <Helmet>
        <title>Zerocam - The Pure Digital Camera App</title>
        <meta name="description" content="Experience pure point-and-shoot photography with Zerocam. No settings, no filters - just natural photography with a minimalist interface. Join our community of authentic mobile photographers." />
        <meta name="keywords" content="Zerocam, camera app, point and shoot, mobile photography, minimalist camera, no filters, pure photography, analog look, photography community, natural photos, simple camera, no settings" />
        <meta property="og:title" content="Zerocam - The Pure Digital Camera App" />
        <meta property="og:description" content="Experience pure point-and-shoot photography with Zerocam. No settings, no filters - just natural photography that tells a story." />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Zerocam - Pure Digital Photography" />
        <meta name="twitter:description" content="Rediscover the joy of photography with Zerocam's minimalist approach. No settings, pure results." />
        <style>
          {`
            body {
              margin: 0;
              padding: 0;
              background-color: black;
              overflow: hidden;
            }
            
            /* Hide scrollbars for all elements */
            * {
              -ms-overflow-style: none;  /* IE and Edge */
              scrollbar-width: none;     /* Firefox */
            }
            
            /* Hide scrollbar for Chrome, Safari and Opera */
            *::-webkit-scrollbar {
              display: none;
            }
            
            /* Navigation responsive styling */
            @media (max-width: 350px) {
              .desktop-nav {
                display: none !important;
              }
              .mobile-nav {
                display: flex !important;
              }
            }
            
            @media (min-width: 481px) {
              .desktop-nav {
                display: flex !important;
              }
              .mobile-nav {
                display: none !important;
              }
            }
          `}
        </style>
      </Helmet>
      <div className="App" style={{
        backgroundColor: 'black',
        height: '100vh',
        width: '100vw',
        margin: 'auto',
        overflow: 'hidden',
        position: 'fixed'
      }}>

        <header className="App-header" style={{ backgroundColor: 'black', height: '100%' }}>
          <WelcomeRiveComponent style={{
            height: '40px',
            position: 'fixed',
            top: '10px'
          }} />

          {/* Desktop Navigation */}
          <div 
            className="desktop-nav"
            style={{
              display: 'flex',
              justifyContent: 'center',
              position: 'fixed',
              top: '60px',
              zIndex: 1000,
              width: '90%',
              maxWidth: '600px',
              gap: '25px'
            }}
          >
            {['original', 'mono', 'about', 'us', 'faq'].map((page) => (
              <button
                key={page}
                onClick={() => setActivePage(page.toLowerCase())}
                style={{
                  background: 'none',
                  border: 'none',
                  color: activePage === page.toLowerCase() ? '#FFC600' : 'white',
                  cursor: 'pointer',
                  fontSize: '16px',
                  fontFamily: 'Geologica, sans-serif',
                  padding: '5px',
                  whiteSpace: 'nowrap'
                }}
              >
                {page}
              </button>
            ))}
          </div>

          {/* Mobile Navigation - Three Dots Menu */}
          <div 
            className="mobile-nav"
            style={{
              display: 'none',
              justifyContent: 'center',
              position: 'fixed',
              top: '60px',
              zIndex: 1000,
              width: '90%'
            }}
          >
            <button
              onClick={() => setMenuOpen(!menuOpen)}
              style={{
                background: 'none',
                border: 'none',
                cursor: 'pointer',
                padding: '5px',
                zIndex: 1001,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <div style={{
                display: 'flex',
                gap: '4px',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                {[1, 2, 3].map(dot => (
                  <div
                    key={dot}
                    style={{
                      width: '8px',
                      height: '8px',
                      borderRadius: '50%',
                      backgroundColor: menuOpen ? '#FFC600' : 'white'
                    }}
                  />
                ))}
              </div>
            </button>

            {/* Dropdown Menu */}
            {menuOpen && (
              <div style={{
                position: 'absolute',
                top: '30px',
                backgroundColor: 'rgba(0, 0, 0, 0.9)',
                borderRadius: '8px',
                padding: '10px',
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                width: '160px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.5)',
                zIndex: 1000
              }}>
                {['original', 'mono', 'about', 'us', 'faq'].map((page) => (
                  <button
                    key={page}
                    onClick={() => {
                      setActivePage(page.toLowerCase());
                      setMenuOpen(false);
                    }}
                    style={{
                      background: 'none',
                      border: 'none',
                      color: activePage === page.toLowerCase() ? '#FFC600' : 'white',
                      cursor: 'pointer',
                      fontSize: '18px',
                      fontFamily: 'Geologica, sans-serif',
                      padding: '8px',
                      textAlign: 'left',
                      borderRadius: '4px',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    {page}
                  </button>
                ))}
              </div>
            )}
          </div>

          <div style={{
            display: 'flex',
            flexDirection: 'column',
            position: 'fixed',
            top: '85px',
            bottom: '70px',
            alignItems: 'center',
            gap: '10px',
            width: '100%',
            padding: '0 20px',
            boxSizing: 'border-box'
          }}>
            {activePage === 'about' ? (
              <div style={{
                color: 'white',
                maxWidth: '800px',
                overflowY: 'auto',
                height: '100%',
                textAlign: 'justify',
                fontFamily: 'Geologica, sans-serif',
                width: '100%',
                msOverflowStyle: 'none', /* IE and Edge */
                scrollbarWidth: 'none', /* Firefox */
              }}
              >
                <style>
                {`
                  div::-webkit-scrollbar {
                    display: none;
                  }
                `}
                </style>
                <h1 style={{ color: '#FFC600', textAlign: 'left' }}>Zerocam: the pure digital camera app.</h1>
                <p>The app which brings pure point-to-shoot photography back!</p>
                <p>Your phone is a small pocket camera, and we believe this camera is made for fun.</p>
                
                <p>Let's be honest: It's not a professional camera with a huge lens, large sensor, complicated settings and hardware controls. It's a small pocket camera with a tiny sensor and a plastic lens. It's not perfect, but it has a charm! Natural noise, soft look, pleasant to the eye, and subtle in-camera app colour grading are distinctive Zerocam looks.</p>
                
                <p>The stock camera app is trying to compensate for "imperfections" with very aggressive AI postprocessing, and the result usually looks unnatural, with an unmistakable HDR look and flatness. Out task to get rid of it.</p>
                
                <h2 style={{ color: '#FFC600', textAlign: 'left' }}>Zero interface.</h2>
                <h3 style={{ color: '#FFC600', textAlign: 'left' }}>Focus on photography, not the camera!</h3>
                <p>Today, almost all photo apps for mobile devices are packed with features, settings, and complicated controls. In our experience, it's becoming less and less accessible because sometimes we want to take a photo, but we have to set up too much: change the exposure, tap to focus, swipe to the correct mode, change the camera… and we miss the shot! Bummer!</p>
                <p>Zerocam is the most accessible camera app ever! Giant shutter button and all settings already made by us.</p>
                <p>No focusing, no exposure correction, no hassle. Just click, and we do the rest. It's the same as on a real disposable camera — pure fun!</p>
                
                <p>We think photography is about telling a story, and nothing should distract you — not artificial postprocessing, ruining your photo with added details that aren't there, or a bloated interface.</p>
              </div>
            ) : activePage === 'faq' ? (
              <div style={{
                color: 'white',
                maxWidth: '800px',
                overflowY: 'auto',
                height: '100%',
                textAlign: 'justify',
                fontFamily: 'Geologica, sans-serif',
                width: '100%',
                msOverflowStyle: 'none', /* IE and Edge */
                scrollbarWidth: 'none', /* Firefox */
              }}
              >
                <style>
                {`
                  div::-webkit-scrollbar {
                    display: none;
                  }
                `}
                </style>
                <h1 style={{ color: '#FFC600', textAlign: 'left' }}>Frequently Asked Questions</h1>
                
                <p><strong style={{ color: '#FFC600' }}>Does Zerocam shoot RAW?</strong><br/>
                — Nope. However, we use RAW in the pipeline for accurate colour grading. And saves JPG for you.</p>
                
                <p><strong style={{ color: '#FFC600' }}>Can I shoot in RAW with Zerocam?</strong><br/>
                — Nope. Your mobile camera is excellent, and RAW is more about a professional approach.</p>
                
                <p><strong style={{ color: '#FFC600' }}>Where are all the settings and options?</strong><br/>
                — Zero. Remember the app name? Yep!</p>
                
                <p><strong style={{ color: '#FFC600' }}>How to focus?</strong><br/>
                — Focus is always on the middle of the frame, as in pocket cameras!</p>
                
                <p><strong style={{ color: '#FFC600' }}>Where is the selfie camera?</strong><br/>
                — Flip your device and get a way better selfie! Yep! The primary camera is better.</p>
                
                <p><strong style={{ color: '#FFC600' }}>Why does it have no options and functions?</strong><br/>
                — This is why this camera app exists. Accessible and simple. Everyone can understand and use it!</p>
                
                <p><strong style={{ color: '#FFC600' }}>What is the difference between RAW shooting and Zerocam results?</strong><br/>
                — RAW is a professional format file; it's only data, not a final photo. Also, the file size is quite large. You have to edit it and it properly, and it makes the photography process longer and more complicated. We appreciate this approach, but sometimes, we want to take photos and be sure they look fantastic.</p>
                
                <p><strong style={{ color: '#FFC600' }}>What is the difference between a PRO mode and a stock Android camera?</strong><br/>
                — In many settings, it still takes photos with heavy postprocessing.</p>
                
                <p><strong style={{ color: '#FFC600' }}>What exactly is zerocam doing when I press the button?</strong><br/>
                — Zerocam works precisely like a conventional digital camera: It takes temporary RAW with our best setting depending on the light and scene, converts it to a flat image, applies our custom subtitle LUT, and saves it in compressed format.</p>
              </div>
            ) : activePage === 'us' ? (
              <div style={{
                color: 'white',
                maxWidth: '800px',
                overflowY: 'auto',
                height: '100%',
                textAlign: 'justify',
                fontFamily: 'Geologica, sans-serif',
                width: '100%',
                msOverflowStyle: 'none', /* IE and Edge */
                scrollbarWidth: 'none', /* Firefox */
              }}
              >
                <style>
                {`
                  div::-webkit-scrollbar {
                    display: none;
                  }
                `}
                </style>
                <h1 style={{ color: '#FFC600', textAlign: 'left' }}>Celebrating the Zerocam Community</h1>
                <p>From the very beginning, Zerocam has resonated with those who cherish photography in its purest and most profound form. The word "photography" (from Greek Phōs, light, and Graphé, drawing) signifies the art of capturing light to create images. It's more than a technical process: it's about evoking emotions, freezing moments, and telling stories.</p>
                
                <p>Our Community has been at the heart of our journey, inspiring us and shaping Zerocam to reflect a shared desire for natural and authentic pictures. We've listened closely to their needs and vision, making them an integral part of what we create.</p>
                
                <p>Here, we proudly present a collection of photos captured with Zerocam by our talented Community.</p>
                
                <div style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(3, 1fr)',
                  gap: '10px',
                  marginTop: '20px',
                  width: '100%'
                }}>
                  {shuffledImages.map((image, index) => (
                    <div key={index} style={{
                      position: 'relative',
                      width: '100%',
                      paddingBottom: '150%', // 2:3 aspect ratio
                      overflow: 'hidden',
                      borderRadius: '8px'
                    }}>
                      <a 
                        href={`https://x.com/${image.handle}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          position: 'absolute',
                          width: '100%',
                          height: '100%',
                          display: 'block',
                          zIndex: 5
                        }}
                      >
                        <img 
                          src={image.src}
                          alt={`By ${image.handle}`}
                          loading="lazy"
                          decoding="async"
                          style={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            top: 0,
                            left: 0
                          }}
                        />
                        <div style={{
                          position: 'absolute',
                          top: '5px',
                          right: '5px',
                          backgroundColor: 'rgba(0,0,0,0.5)',
                          padding: '2px 5px',
                          borderRadius: '4px',
                          fontSize: '8px',
                          color: '#FFFFFF',
                          zIndex: 10
                        }}>
                          {image.handle}
                        </div>
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <>
                <img
                  src={activePage === 'mono' ? monoImages[currentMonoImageIndex] : images[currentImageIndex]}
                  loading="lazy"
                  alt={`By ${activePage === 'mono' ? xMonoHandle[currentMonoImageIndex] : xHandle[currentImageIndex]}`}
                  style={{
                    width: 'auto',
                    height: '60vh',
                    aspectRatio: '2/3',
                    objectFit: 'cover',
                    borderRadius: '16px',
                    overflow: 'hidden',
                    maxWidth: '90%',
                  }}
                  decoding="async"
                />

                <a
                  href={activePage === 'mono' ? `https://x.com/${xMonoHandle[currentMonoImageIndex]}` : `https://x.com/${xHandle[currentImageIndex]}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    textDecoration: 'none',
                    width: '90%',
                    maxWidth: '400px',
                    alignSelf: 'center',
                    margin: '0'
                  }}
                >
                  <div style={{
                    fontSize: '10px',
                    color: 'white',
                    textAlign: 'right',
                    width: '100%',
                    fontFamily: 'Geologica, sans-serif'
                  }}>
                    {activePage === 'mono' ? xMonoHandle[currentMonoImageIndex] : xHandle[currentImageIndex]}
                  </div>
                </a>
                {activePage === 'mono' ? (
                  <MonoButtonRive style={{
                    zIndex: 0,
                    height: '100px',
                    width: '45%',
                    borderRadius: '100px',
                    overflow: 'hidden',
                  }} onMouseUp={() => {
                    activePage === 'mono'
                    ? setCurrentMonoImageIndex((prev) => (prev + 1) % monoImages.length)
                    : setCurrentImageIndex((prev) => (prev + 1) % images.length);
                  }} onTouchEnd={(e) => {
                    e.preventDefault(); // Prevent any default touch behavior
                    activePage === 'mono'
                    ? setCurrentMonoImageIndex((prev) => (prev + 1) % monoImages.length)
                    : setCurrentImageIndex((prev) => (prev + 1) % images.length);
                  }} />
                ) : (
                  <ButtonRive style={{
                    zIndex: 0,
                    height: '100px',
                    width: '45%',
                    borderRadius: '100px',
                    overflow: 'hidden',
                  }} onMouseUp={() => {
                    activePage === 'mono'
                    ? setCurrentMonoImageIndex((prev) => (prev + 1) % monoImages.length)
                    : setCurrentImageIndex((prev) => (prev + 1) % images.length);
                  }} onTouchEnd={(e) => {
                    e.preventDefault(); // Prevent any default touch behavior
                    activePage === 'mono'
                    ? setCurrentMonoImageIndex((prev) => (prev + 1) % monoImages.length)
                    : setCurrentImageIndex((prev) => (prev + 1) % images.length);
                  }} />
                )}
              </>
            )}
            
          </div>

          <div style={{
            display: 'flex',
            maxWidth: '100%',
            marginLeft: '10px',
            marginRight: '10px',
            gap: '10px',
            position: 'fixed',
            bottom: '10px'
          }}>
            <a href={activePage === 'mono' ? "https://apps.apple.com/app/id6520394061" : "https://apps.apple.com/app/id6483933438"}>
              <img src={appStoreButton} alt="Download on App Store" style={{
                maxHeight: '40px',
                maxWidth: '100%'
              }} />
            </a>
            {activePage !== 'mono' && (
              <a href="https://play.google.com/store/apps/details?id=com.zerocam.app">
                <img src={googlePlayButton} alt="Get it on Google Play" style={{
                  maxHeight: '40px',
                  maxWidth: '100%'
                }} />
              </a>
            )}
          </div>
        </header>
      </div>
    </>
  );
}

export default App;
